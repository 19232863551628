import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import ProductList from './components/ProductList';
import AddProduct from './components/AddProduct';
import BatchAddProduct from './components/BatchAddProduct';
import EditProduct from './components/EditProduct';
import ViewProduct from './components/ViewProduct';
import EditRates from './components/EditRates';
import VendorManagement from './components/VendorManagement';
import QuickCalculator from './components/QuickCalculator';
import Login from './components/Login';
import './styles.css';

// 使用 UUID 取代原有的 deviceId，並僅保存 24 小時
function getOrCreateDeviceId() {
  const storedData = localStorage.getItem('deviceIdData');
  const now = Date.now();

  if (storedData) {
    try {
      const { deviceId, timestamp } = JSON.parse(storedData);
      if (now - timestamp < 24 * 60 * 60 * 1000) {
        return deviceId;
      }
    } catch (error) {
      console.error("解析 deviceIdData 失敗：", error);
    }
  }

  const newDeviceId = crypto.randomUUID();
  const newData = {
    deviceId: newDeviceId,
    timestamp: now
  };
  localStorage.setItem('deviceIdData', JSON.stringify(newData));
  return newDeviceId;
}

function AppContent() {
  const [country, setCountry] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getOrCreateDeviceId();
    const lastLogin = localStorage.getItem('lastLogin');
    if (lastLogin) {
      const lastLoginDate = new Date(lastLogin);
      const currentDate = new Date();
      if (lastLoginDate.toDateString() === currentDate.toDateString()) {
        setIsLoggedIn(true);
      }
    }
    setIsLoading(false);
  }, []);

  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
    navigate(`/products/${selectedCountry}`);
  };

  const handleLogin = () => {
    localStorage.setItem('lastLogin', new Date().toString());
    setIsLoggedIn(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="App">
      {!country ? (
        <Home onCountrySelect={handleCountrySelect} />
      ) : (
        <>
          <nav>
            <button onClick={() => setCountry(null)} className="switch-country-button">
              切換國家
            </button>
            <button onClick={() => setIsCollapsed(!isCollapsed)} className="menu-button">
              {isCollapsed ? "展開選單" : "收合選單"}
            </button>
            {!isCollapsed && (
              <ul>
                <li><Link to={`/products/${country}`}>商品列表</Link></li>
                <li><Link to={`/add/${country}`}>新增商品</Link></li>
                <li><Link to={`/batch-add/${country}`}>批次新增商品</Link></li>
                <li><Link to={`/edit-rates/${country}`}>編輯匯率運費</Link></li>
                <li><Link to={`/vendor/${country}`}>廠商管理</Link></li>
                <li><Link to={`/quick-calculator/${country}`}>快速計算</Link></li>
              </ul>
            )}
          </nav>
          <Routes>
            <Route path="/products/:country" element={<ProductList />} />
            <Route path="/search/:country" element={<ProductList />} />
            <Route path="/add/:country" element={<AddProduct />} />
            <Route path="/batch-add/:country" element={<BatchAddProduct />} />
            <Route path="/edit-rates/:country" element={<EditRates />} />
            <Route path="/view/:country/:id" element={<ViewProduct />} />
            <Route path="/edit/:country/:id" element={<EditProduct />} />
            <Route path="/vendor/:country" element={<VendorManagement />} />
            <Route path="/quick-calculator/:country" element={<QuickCalculator />} />
          </Routes>
        </>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
