import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import db from '../firebaseConfig';
import { doc, getDoc, setDoc, collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import '../styles.css';

function EditProduct() {
  const { country, id } = useParams();
  const navigate = useNavigate();

  // 增加缺失的狀態欄位 newStyle 與 newStyleBarcode 以及 weight
  const [formData, setFormData] = useState({
    image: '',
    name: '',
    itemNumber: '',
    livePrice: '',
    onlinePrice: '',
    remarks: '',
    costKoreanWon: '',
    costTWD: '',
    costTHB: '',
    costRMB: '',
    weight: '',             // 新增：重量欄位
    costType: country === 'korea' ? 'KRW' : country === 'china' ? 'TWD' : 'THB',
    vendor: '',
    styles: [],
    styleBarcodes: [],
    newStyle: '',           // 新增：新增樣式的名稱
    newStyleBarcode: ''     // 新增：新增樣式的條碼
  });

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const collectionName =
          country === 'korea'
            ? 'koreanProducts'
            : country === 'china'
            ? 'chinaProducts'
            : 'thaiProducts';
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          // 設定資料時，若缺少相關欄位則給予預設值
          setFormData({
            image: data.image || '',
            name: data.name || '',
            itemNumber: data.itemNumber || '',
            livePrice: data.livePrice || '',
            onlinePrice: data.onlinePrice || '',
            remarks: data.remarks || '',
            costKoreanWon: data.costKoreanWon || '',
            costTWD: data.costTWD || '',
            costTHB: data.costTHB || '',
            costRMB: data.costRMB || '',
            weight: data.weight || '',
            costType: data.costKoreanWon ? 'KRW' : data.costTHB ? 'THB' : 'TWD',
            vendor: data.vendor || '',
            styles: data.styles || [],
            styleBarcodes: data.styleBarcodes || [],
            newStyle: '',           // 預設為空
            newStyleBarcode: ''     // 預設為空
          });
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product: ", error);
      }
    };
    fetchProduct();
  }, [country, id]);

  useEffect(() => {
    const fetchVendors = async () => {
      const vendorsRef = collection(db, 'vendors');
      const q = query(vendorsRef, where('country', '==', country));
      const snapshot = await getDocs(q);
      const vendorList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorList);
    };
    fetchVendors();
  }, [country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'vendor') {
      localStorage.setItem('lastSelectedVendor', value.trim());
    }
  };

  // 根據 localStorage 中最後選擇的廠商排序，該廠商排最前
  const lastSelectedVendor = localStorage.getItem('lastSelectedVendor') || '';
  const sortedVendors = [...vendors].sort((a, b) => {
    if (a.name === lastSelectedVendor) return -1;
    if (b.name === lastSelectedVendor) return 1;
    return a.name.localeCompare(b.name);
  });

  const handleCostTypeChange = (e) => {
    const newCostType = e.target.value;
    setFormData(prev => ({
      ...prev,
      costType: newCostType,
      costKoreanWon: newCostType === 'KRW' ? '' : prev.costKoreanWon,
      costTHB: newCostType === 'THB' ? '' : prev.costTHB,
      costTWD: newCostType === 'TWD' ? '' : prev.costTWD,
      costRMB: newCostType === 'RMB' ? '' : prev.costRMB,
    }));
  };

  const addNewVendor = async (vendorName) => {
    if (!vendorName.trim()) return;
    const vendorsRef = collection(db, 'vendors');
    const q = query(vendorsRef, where('country', '==', country), where('name', '==', vendorName));
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      const docRef = await addDoc(vendorsRef, { name: vendorName, country });
      setVendors(prev => [...prev, { id: docRef.id, name: vendorName, country }]);
    }
  };

  const handleSave = async () => {
    if (formData.vendor.trim() !== '') {
      const vendorName = formData.vendor.trim();
      const exists = vendors.find(v => v.name === vendorName);
      if (!exists) {
        await addNewVendor(vendorName);
      }
    }

    const updatedProduct = {
      ...formData,
      history: [
        ...(formData.history || []),
        {
          date: new Date().toLocaleDateString(),
          costKoreanWon: formData.costKoreanWon,
          costTWD: formData.costTWD,
          costTHB: formData.costTHB,
          livePrice: formData.livePrice,
          onlinePrice: formData.onlinePrice,
          remarks: formData.remarks,
          vendor: formData.vendor
        }
      ]
    };

    try {
      const collectionName =
        country === 'korea'
          ? 'koreanProducts'
          : country === 'china'
          ? 'chinaProducts'
          : 'thaiProducts';
      await setDoc(doc(db, collectionName, id), updatedProduct);
      navigate(`/view/${country}/${id}`);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const addStyle = () => {
    const { newStyle, newStyleBarcode } = formData;
    const style = newStyle.trim() === '' ? '無樣式' : newStyle.trim();
    if (newStyleBarcode.trim() !== '') {
      setFormData(prev => ({
        ...prev,
        styles: [...prev.styles, style],
        styleBarcodes: [...prev.styleBarcodes, newStyleBarcode],
        newStyle: '',
        newStyleBarcode: ''
      }));
    }
  };

  const removeStyle = (index) => {
    const updatedStyles = [...formData.styles];
    const updatedBarcodes = [...formData.styleBarcodes];
    updatedStyles.splice(index, 1);
    updatedBarcodes.splice(index, 1);
    setFormData(prev => ({
      ...prev,
      styles: updatedStyles,
      styleBarcodes: updatedBarcodes
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({ ...prev, image: reader.result }));
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container product-edit">
      <h2>編輯商品</h2>
      <div className="form-group">
        <label>名稱</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>料號</label>
        <input type="text" name="itemNumber" value={formData.itemNumber} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>直播價</label>
        <input type="text" name="livePrice" value={formData.livePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>商成價</label>
        <input type="text" name="onlinePrice" value={formData.onlinePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>備註</label>
        <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>成本類型</label>
        <select name="costType" value={formData.costType} onChange={handleCostTypeChange}>
          <option value="TWD">台幣</option>
          {country === 'korea' && <option value="KRW">韓幣</option>}
          {country === 'thailand' && <option value="THB">泰銖</option>}
        </select>
      </div>
      {formData.costType === 'TWD' && (
        <div className="form-group">
          <label>成本 (台幣)</label>
          <input type="text" name="costTWD" value={formData.costTWD} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'KRW' && (
        <div className="form-group">
          <label>成本 (韓幣)</label>
          <input type="text" name="costKoreanWon" value={formData.costKoreanWon} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'THB' && (
        <div className="form-group">
          <label>成本 (泰銖)</label>
          <input type="text" name="costTHB" value={formData.costTHB} onChange={handleChange} />
        </div>
      )}
      <div className="form-group">
        <label>重量</label>
        <input type="text" name="weight" value={formData.weight} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>新增樣式</label>
        <input type="text" name="newStyle" value={formData.newStyle} onChange={handleChange} placeholder="樣式名稱" />
        <input type="text" name="newStyleBarcode" value={formData.newStyleBarcode} onChange={handleChange} placeholder="樣式條碼" />
        <button onClick={addStyle}>新增樣式</button>
      </div>
      <div className="form-group">
        <label>樣式列表</label>
        <ul>
          {formData.styles.map((style, index) => (
            <li key={index}>
              {style} ({formData.styleBarcodes[index]})
              <button onClick={() => removeStyle(index)}>刪除</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="form-group">
        <label>商品圖片</label>
        <input type="file" onChange={handleImageUpload} />
        {formData.image && <img src={formData.image} alt="商品圖片" className="product-image-preview" />}
      </div>
      <div className="form-group">
        <label>廠商</label>
        <input 
          type="text" 
          name="vendor" 
          value={formData.vendor} 
          onChange={handleChange} 
          list="vendorList" 
          placeholder="選擇或輸入廠商名稱" 
        />
        <datalist id="vendorList">
          {sortedVendors.map(vendor => (
            <option key={vendor.id} value={vendor.name} />
          ))}
        </datalist>
      </div>
      <div className="form-group">
        <button onClick={handleSave}>保存</button>
      </div>
    </div>
  );
}

export default EditProduct;
