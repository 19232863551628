import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import db from '../firebaseConfig';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import '../styles.css';

function VendorManagement() {
  const { country } = useParams();
  const [vendors, setVendors] = useState([]);
  const [newVendorName, setNewVendorName] = useState('');

  const fetchVendors = async () => {
    const vendorsRef = collection(db, 'vendors');
    const q = query(vendorsRef, where('country', '==', country));
    const snapshot = await getDocs(q);
    const vendorList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setVendors(vendorList);
  };

  useEffect(() => {
    fetchVendors();
  }, [country]);

  const handleAddVendor = async () => {
    if (!newVendorName.trim()) return;
    const vendorsRef = collection(db, 'vendors');
    await addDoc(vendorsRef, { name: newVendorName.trim(), country });
    setNewVendorName('');
    fetchVendors();
  };

  const handleDeleteVendor = async (vendorId) => {
    if(window.confirm("確定要刪除此廠商嗎？")) {
      await deleteDoc(doc(db, 'vendors', vendorId));
      fetchVendors();
    }
  };

  return (
    <div className="container">
      <h2>廠商管理</h2>
      <div>
        <input 
          type="text" 
          value={newVendorName} 
          onChange={(e) => setNewVendorName(e.target.value)} 
          placeholder="新增廠商名稱" 
        />
        <button onClick={handleAddVendor}>新增廠商</button>
      </div>
      <ul>
        {vendors.map(vendor => (
          <li key={vendor.id}>
            {vendor.name}
            <button onClick={() => handleDeleteVendor(vendor.id)}>刪除</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VendorManagement;
