import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import db from '../firebaseConfig';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import '../styles.css';

function AddProduct() {
  const { country } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    image: '',
    name: '',
    itemNumber: '',
    livePrice: '',
    onlinePrice: '',
    remarks: '',
    costKoreanWon: '',
    costTWD: '',
    costTHB: '',
    costRMB: '',
    weight: '',
    styles: [],
    styleBarcodes: [],
    // 根據 country 決定成本類型：韓國輸入韓幣、泰國輸入泰銖，中國則採用台幣（可依需求調整）
    costType: country === 'korea' ? 'KRW' : country === 'china' ? 'TWD' : 'THB',
    newStyle: '',
    newStyleBarcode: new URLSearchParams(location.search).get('barcode') || '',
    vendor: ''
  });
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const fetchVendors = async () => {
      const vendorsRef = collection(db, 'vendors');
      const q = query(vendorsRef, where('country', '==', country));
      const snapshot = await getDocs(q);
      const vendorList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorList);
    };
    fetchVendors();
  }, [country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'vendor') {
      localStorage.setItem('lastSelectedVendor', value.trim());
    }
  };

  // 根據 localStorage 中最後選擇的廠商排序，該廠商排第一
  const lastSelectedVendor = localStorage.getItem('lastSelectedVendor') || '';
  const sortedVendors = [...vendors].sort((a, b) => {
    if (a.name === lastSelectedVendor) return -1;
    if (b.name === lastSelectedVendor) return 1;
    return a.name.localeCompare(b.name);
  });

  const handleCostTypeChange = (e) => {
    const newCostType = e.target.value;
    setFormData(prev => ({
      ...prev,
      costType: newCostType,
      costKoreanWon: newCostType === 'KRW' ? '' : prev.costKoreanWon,
      costTHB: newCostType === 'THB' ? '' : prev.costTHB,
      costTWD: newCostType === 'TWD' ? '' : prev.costTWD,
      costRMB: newCostType === 'RMB' ? '' : prev.costRMB,
    }));
  };

  // 若輸入的廠商不存在則自動新增
  const addNewVendor = async (vendorName) => {
    if (!vendorName.trim()) return;
    const vendorsRef = collection(db, 'vendors');
    const q = query(vendorsRef, where('country', '==', country), where('name', '==', vendorName));
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      const docRef = await addDoc(vendorsRef, { name: vendorName, country });
      setVendors(prev => [...prev, { id: docRef.id, name: vendorName, country }]);
    }
  };

  const handleSave = async () => {
    // 如果使用者直接在欄位中輸入廠商（即 vendorInput 與 formData.vendor 同步），則在保存時自動檢查並新增
    if (formData.vendor.trim() !== '') {
      const vendorName = formData.vendor.trim();
      const exists = vendors.find(v => v.name === vendorName);
      if (!exists) {
        await addNewVendor(vendorName);
      }
    }

    // 處理樣式與條碼
    const styles = formData.styles.length > 0 ? formData.styles : [];
    const styleBarcodes = formData.styleBarcodes.length > 0 ? formData.styleBarcodes : [];
    if (formData.newStyleBarcode.trim() !== '' && formData.newStyle.trim() === '') {
      styles.push('無樣式');
      styleBarcodes.push(formData.newStyleBarcode.trim());
    }

    const newProduct = {
      ...formData,
      styles,
      styleBarcodes,
      history: []
    };

    try {
      const collectionName =
        country === 'korea'
          ? 'koreanProducts'
          : country === 'china'
          ? 'chinaProducts'
          : 'thaiProducts';
      const docRef = await addDoc(collection(db, collectionName), newProduct);
      console.log("Document written with ID: ", docRef.id);
      navigate(`/view/${country}/${docRef.id}`);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const addStyle = () => {
    const { newStyle, newStyleBarcode } = formData;
    if (newStyleBarcode.trim() !== '') {
      setFormData(prev => ({
        ...prev,
        styles: [...prev.styles, newStyle],
        styleBarcodes: [...prev.styleBarcodes, newStyleBarcode],
        newStyle: '',
        newStyleBarcode: ''
      }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({ ...prev, image: reader.result }));
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container product-edit">
      <h2>新增商品</h2>
      {/* 圖片上傳 */}
      <div className="form-group">
        <label>商品圖片</label>
        <input type="file" onChange={handleImageUpload} />
        {formData.image && (
          <img src={formData.image} alt="商品圖片" className="product-image-preview" />
        )}
      </div>
      {/* 商品基本資訊 */}
      <div className="form-group">
        <label>名稱</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>料號</label>
        <input type="text" name="itemNumber" value={formData.itemNumber} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>直播價</label>
        <input type="text" name="livePrice" value={formData.livePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>商城價</label>
        <input type="text" name="onlinePrice" value={formData.onlinePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>備註</label>
        <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} />
      </div>
      {/* 成本類型與成本 */}
      <div className="form-group">
        <label>成本類型</label>
        <select name="costType" value={formData.costType} onChange={handleCostTypeChange}>
          <option value="TWD">台幣</option>
          {country === 'korea' && <option value="KRW">韓幣</option>}
          {country === 'thailand' && <option value="THB">泰銖</option>}
          {country === 'china' && <option value="RMB">人民幣</option>}
        </select>
      </div>
      {formData.costType === 'TWD' && (
        <div className="form-group">
          <label>成本 (台幣)</label>
          <input type="text" name="costTWD" value={formData.costTWD} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'KRW' && (
        <div className="form-group">
          <label>成本 (韓幣)</label>
          <input type="text" name="costKoreanWon" value={formData.costKoreanWon} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'THB' && (
        <div className="form-group">
          <label>成本 (泰銖)</label>
          <input type="text" name="costTHB" value={formData.costTHB} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'RMB' && (
        <div className="form-group">
          <label>成本 (人民幣)</label>
          <input type="text" name="costRMB" value={formData.costRMB} onChange={handleChange} />
        </div>
      )}
      {/* 重量 */}
      <div className="form-group">
        <label>重量</label>
        <input type="text" name="weight" value={formData.weight} onChange={handleChange} />
      </div>
      {/* 樣式與條碼 */}
      <div className="form-group">
        <label>新增樣式</label>
        <input type="text" name="newStyle" value={formData.newStyle} onChange={handleChange} placeholder="樣式名稱" />
        <input type="text" name="newStyleBarcode" value={formData.newStyleBarcode} onChange={handleChange} placeholder="樣式條碼" />
        <button onClick={addStyle}>新增樣式</button>
      </div>
      <div className="form-group">
        <label>樣式列表</label>
        <ul>
          {formData.styles.map((style, index) => (
            <li key={index}>
              {style} ({formData.styleBarcodes[index]})
            </li>
          ))}
        </ul>
      </div>
      {/* 廠商欄位：直接顯示輸入框加 datalist */}
      <div className="form-group">
        <label>廠商</label>
        <input 
          type="text" 
          name="vendor" 
          value={formData.vendor} 
          onChange={handleChange} 
          list="vendorList" 
          placeholder="選擇或輸入廠商" 
          style={{ fontSize: '1.25rem' }}
        />
        <datalist id="vendorList">
          {sortedVendors.map(vendor => (
            <option key={vendor.id} value={vendor.name} />
          ))}
        </datalist>
        <small>若廠商不存在，直接輸入後保存會自動新增</small>
      </div>
      <button onClick={handleSave}>保存</button>
    </div>
  );
}

export default AddProduct;
