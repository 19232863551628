import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import db from '../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import '../styles.css';

function EditRates() {
  const { country } = useParams();
  
  // 預設值（僅供參考）
  const [airRate, setAirRate] = useState(144);
  const [seaRate, setSeaRate] = useState(95);
  const [transitRate, setTransitRate] = useState(125);
  const [exchangeRate, setExchangeRate] = useState(30);
  const [shippingRate, setShippingRate] = useState(1); // 中國專用

  useEffect(() => {
    const fetchRates = async () => {
      const ratesRef = doc(db, 'rates', country);
      const docSnap = await getDoc(ratesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (country === 'china') {
          setExchangeRate(data.exchangeRate || 30);
          setShippingRate(data.shippingRate || 1);
        } else if (country === 'korea') {
          setAirRate(data.airRate || 144);
          setSeaRate(data.seaRate || 95);
          setTransitRate(data.transitRate || 125);
          setExchangeRate(data.exchangeRate || 30);
        } else { // 泰國
          setAirRate(data.airRate || 144);
          setExchangeRate(data.exchangeRate || 0.9);
        }
      }
    };

    fetchRates();
  }, [country]);

  const handleSave = async () => {
    const ratesRef = doc(db, 'rates', country);
    let ratesData = {};
    if (country === 'china') {
      ratesData = {
        exchangeRate,
        shippingRate,
      };
    } else if (country === 'korea') {
      ratesData = {
        airRate,
        seaRate,
        transitRate,
        exchangeRate,
      };
    } else {
      ratesData = {
        airRate,
        exchangeRate,
      };
    }
    await setDoc(ratesRef, ratesData);
    alert('匯率已保存');
  };

  return (
    <div>
      <h2>編輯匯率</h2>
      {country === 'china' ? (
        <>
          <p>
            匯率 (人民幣轉台幣):
            <input
              type="number"
              value={exchangeRate}
              onChange={(e) => setExchangeRate(Number(e.target.value))}
            />
          </p>
          <p>
            海快雜貨 運費倍率:
            <input
              type="number"
              value={shippingRate}
              onChange={(e) => setShippingRate(Number(e.target.value))}
            />
          </p>
        </>
      ) : country === 'korea' ? (
        <>
          <p>
            空運公斤:
            <input
              type="number"
              value={airRate}
              onChange={(e) => setAirRate(Number(e.target.value))}
            />
          </p>
          <p>
            海運公斤:
            <input
              type="number"
              value={seaRate}
              onChange={(e) => setSeaRate(Number(e.target.value))}
            />
          </p>
          <p>
            轉機公斤:
            <input
              type="number"
              value={transitRate}
              onChange={(e) => setTransitRate(Number(e.target.value))}
            />
          </p>
          <p>
            匯率 (韓幣轉台幣):
            <input
              type="number"
              value={exchangeRate}
              onChange={(e) => setExchangeRate(Number(e.target.value))}
            />
          </p>
        </>
      ) : (
        <>
          <p>
            空運公斤:
            <input
              type="number"
              value={airRate}
              onChange={(e) => setAirRate(Number(e.target.value))}
            />
          </p>
          <p>
            匯率 (泰銖轉台幣):
            <input
              type="number"
              value={exchangeRate}
              onChange={(e) => setExchangeRate(Number(e.target.value))}
            />
          </p>
        </>
      )}
      <button onClick={handleSave}>保存</button>
    </div>
  );
}

export default EditRates;

