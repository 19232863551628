import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import db from '../firebaseConfig';
import '../styles.css';

function QuickCalculator() {
  const { country } = useParams();
  
  // 對中國輸入為人民幣；對韓國輸入韓幣；泰國則輸入泰銖
  const [baseCost, setBaseCost] = useState('');
  const [weight, setWeight] = useState('');
  const [discount, setDiscount] = useState('100'); // 預設 100%

  // Rates
  const [exchangeRate, setExchangeRate] = useState(null);
  const [airRate, setAirRate] = useState(null);
  const [seaRate, setSeaRate] = useState(null);
  const [transitRate, setTransitRate] = useState(null);
  const [shippingRate, setShippingRate] = useState(null); // 中國用

  const [ratesError, setRatesError] = useState(null);

  useEffect(() => {
    async function fetchRates() {
      try {
        const ratesRef = doc(db, 'rates', country);
        const docSnap = await getDoc(ratesRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (country === 'china') {
            setExchangeRate(data.exchangeRate);
            setShippingRate(data.shippingRate);
          } else if (country === 'korea') {
            setExchangeRate(data.exchangeRate);
            setAirRate(data.airRate);
            setSeaRate(data.seaRate);
            setTransitRate(data.transitRate);
          } else {
            setExchangeRate(data.exchangeRate);
            setAirRate(data.airRate);
          }
        } else {
          setRatesError(`找不到 country "${country}" 的匯率設定`);
        }
      } catch (error) {
        setRatesError("讀取匯率時發生錯誤：" + error.message);
      }
    }
    if (country) fetchRates();
  }, [country]);

  if (ratesError) return <p>{ratesError}</p>;
  if (
    exchangeRate === null ||
    (country === 'china'
      ? shippingRate === null
      : country === 'korea'
      ? (airRate === null || seaRate === null || transitRate === null)
      : airRate === null)
  ) {
    return <p>載入中…</p>;
  }

  // 僅對於韓國顯示預設數字按鈕
  const presetValues = [];
  if (country === 'korea') {
    for (let value = 1000; value <= 10000; value += 500) {
      presetValues.push(value);
    }
  }
  const handlePresetClick = (value) => {
    setBaseCost(value);
  };

  const parsedCost = parseFloat(baseCost) || 0;
  const parsedWeight = parseFloat(weight) || 0;
  const parsedDiscount = parseFloat(discount) || 100;

  let convertedCost, discountedConvertedCost, finalTotals;
  if (country === 'china') {
    // 中國：成本以人民幣輸入
    convertedCost = (parsedCost * exchangeRate).toFixed(2);
    discountedConvertedCost = (parsedCost * exchangeRate * (parsedDiscount / 100)).toFixed(2);
    const shippingCost = (parsedWeight * shippingRate * exchangeRate).toFixed(2);
    const total = (parseFloat(discountedConvertedCost) + parseFloat(shippingCost)).toFixed(2);
    finalTotals = { shippingCost, total };
  } else {
    // 韓國／泰國：成本換算方式為：成本除以匯率
    convertedCost = (parsedCost / exchangeRate).toFixed(2);
    if (country === 'korea') {
      const airShipping = (parsedWeight * airRate).toFixed(2);
      const seaShipping = (parsedWeight * seaRate).toFixed(2);
      const transitShipping = (parsedWeight * transitRate).toFixed(2);
      finalTotals = {
        air: ((parseFloat(convertedCost) * (parsedDiscount / 100)) + parseFloat(airShipping)).toFixed(2),
        sea: ((parseFloat(convertedCost) * (parsedDiscount / 100)) + parseFloat(seaShipping)).toFixed(2),
        transit: ((parseFloat(convertedCost) * (parsedDiscount / 100)) + parseFloat(transitShipping)).toFixed(2)
      };
    } else { // thailand
      const airShipping = (parsedWeight * airRate).toFixed(2);
      finalTotals = {
        air: ((parseFloat(convertedCost) * (parsedDiscount / 100)) + parseFloat(airShipping)).toFixed(2)
      };
    }
  }

  return (
    <div className="container quick-calculator" style={{ padding: '1rem', textAlign: 'center', maxWidth: '100%', boxSizing: 'border-box' }}>
      <h2 style={{ fontSize: '1.75rem', marginBottom: '1rem' }}>快速計算</h2>

      <div style={{ marginBottom: '0.5rem' }}>
        <label style={{ fontSize: '1.25rem', marginRight: '0.5rem' }}>
          {country === 'china' ? '人民幣成本：' : (country === 'korea' ? '韓幣成本：' : '成本：')}
        </label>
        <input
          type="number"
          value={baseCost}
          onChange={(e) => setBaseCost(e.target.value)}
          style={{ fontSize: '1.25rem', padding: '0.3rem', width: '150px' }}
        />
      </div>

      {country === 'korea' && (
        <div style={{ marginBottom: '0.5rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {presetValues.map((value) => (
            <button key={value} onClick={() => handlePresetClick(value)} style={{ fontSize: '1.1rem', margin: '0.3rem', padding: '0.3rem 0.5rem' }}>
              {value}
            </button>
          ))}
        </div>
      )}

      <div style={{ marginBottom: '0.5rem' }}>
        <label style={{ fontSize: '1.25rem', marginRight: '0.5rem' }}>重量：</label>
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          style={{ fontSize: '1.25rem', padding: '0.3rem', width: '150px' }}
        />
      </div>

      <div style={{ marginBottom: '0.5rem' }}>
        <label style={{ fontSize: '1.25rem', marginRight: '0.5rem' }}>折扣 (%)：</label>
        <input
          type="number"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          style={{ fontSize: '1.25rem', padding: '0.3rem', width: '150px' }}
        />
      </div>

      <hr style={{ margin: '0.75rem 0' }} />

      {country === 'china' ? (
        <div style={{ fontSize: '1.25rem', lineHeight: '1.5rem', textAlign: 'left', margin: '0 auto', maxWidth: '320px' }}>
          <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
            <strong>原始換算成本：</strong> {convertedCost} TWD
          </div>
          <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
            <strong>折扣後成本：</strong> {discountedConvertedCost} TWD
          </div>
          <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
            <strong>🚢海快雜貨 運費：</strong> {finalTotals.shippingCost} TWD
          </div>
          <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
            <strong>折扣後總成本：</strong> {finalTotals.total} TWD
          </div>
        </div>
      ) : (
        <div style={{ fontSize: '1.25rem', lineHeight: '1.5rem', textAlign: 'left', margin: '0 auto', maxWidth: '320px' }}>
          <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
            <strong>換算成本：</strong> {convertedCost} TWD
          </div>
          {country === 'korea' && (
            <>
              <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
                <strong>空運：</strong> 運費 {(parsedWeight * airRate).toFixed(2)} TWD / 總成本 {finalTotals.air} TWD
              </div>
              <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
                <strong>海運：</strong> 運費 {(parsedWeight * seaRate).toFixed(2)} TWD / 總成本 {finalTotals.sea} TWD
              </div>
              <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
                <strong>轉機：</strong> 運費 {(parsedWeight * transitRate).toFixed(2)} TWD / 總成本 {finalTotals.transit} TWD
              </div>
            </>
          )}
          {country === 'thailand' && (
            <div style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '0.5rem', background: '#f9f9f9' }}>
              <strong>空運：</strong> 運費 {(parsedWeight * airRate).toFixed(2)} TWD / 總成本 {finalTotals.air} TWD
            </div>
          )}
        </div>
      )}

    </div>
  );
}

export default QuickCalculator;
