import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import db from '../firebaseConfig';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import '../styles.css';

function ViewProduct() {
  const { country, id } = useParams();
  const navigate = useNavigate();
  
  const [product, setProduct] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [airRate, setAirRate] = useState(144);
  const [seaRate, setSeaRate] = useState(95);
  const [transitRate, setTransitRate] = useState(125);
  const [exchangeRate, setExchangeRate] = useState(30);
  const [shippingRate, setShippingRate] = useState(null); // 針對中國
  const [showBarcode, setShowBarcode] = useState({});

  // 內嵌編輯廠商相關 state
  const [editingVendor, setEditingVendor] = useState(false);
  const [vendorInput, setVendorInput] = useState('');

  // 取得廠商列表
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    const fetchVendors = async () => {
      const vendorsRef = collection(db, 'vendors');
      const q = query(vendorsRef, where('country', '==', country));
      const snapshot = await getDocs(q);
      const vendorList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorList);
    };
    if (country) fetchVendors();
  }, [country]);

  // 根據 localStorage 最後選擇的廠商排序
  const lastSelectedVendor = localStorage.getItem('lastSelectedVendor') || '';
  const sortedVendors = [...vendors].sort((a, b) => {
    if (a.name === lastSelectedVendor) return -1;
    if (b.name === lastSelectedVendor) return 1;
    return a.name.localeCompare(b.name);
  });

  // 讀取匯率與運費設定
  useEffect(() => {
    const fetchRates = async () => {
      const ratesRef = doc(db, 'rates', country);
      const docSnap = await getDoc(ratesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (country === 'china') {
          setExchangeRate(data.exchangeRate || 30);
          setShippingRate(data.shippingRate || 1);
        } else if (country === 'korea') {
          setAirRate(data.airRate || 144);
          setSeaRate(data.seaRate || 95);
          setTransitRate(data.transitRate || 125);
          setExchangeRate(data.exchangeRate || 30);
        } else {
          setAirRate(data.airRate || 144);
          setExchangeRate(data.exchangeRate || 0.9);
        }
      }
    };
    if (country) fetchRates();
  }, [country]);

  // 讀取產品資料
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const collectionName =
          country === 'korea'
            ? 'koreanProducts'
            : country === 'china'
            ? 'chinaProducts'
            : 'thaiProducts';
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setProduct(data);
          setVendorInput(data.vendor || '');
          const initialShow = {};
          data.styles?.forEach((_, idx) => { initialShow[idx] = false; });
          setShowBarcode(initialShow);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product: ", error);
      }
    };
    if (id) fetchProduct();
  }, [country, id]);

  if (!product) {
    return (
      <p style={{ fontSize: '1.25rem', textAlign: 'center', padding: '1rem' }}>
        找不到該商品
      </p>
    );
  }

  const {
    image,
    name,
    itemNumber,
    livePrice,
    onlinePrice,
    remarks,
    costKoreanWon,
    costTWD,
    costTHB,
    costRMB,
    weight,
    styles,
    styleBarcodes,
    history
  } = product;

  const renderPriceInfo = () => {
    if (Number(livePrice) === Number(onlinePrice)) {
      return (
        <p style={{ fontWeight: 'bold', color: 'red', fontSize: '1.5rem', margin: '0.5rem 0' }}>
          直播價: {Math.ceil(Number(livePrice))}
        </p>
      );
    } else {
      return (
        <>
          <p style={{ fontWeight: 'bold', color: 'red', fontSize: '1.5rem', margin: '0.3rem 0' }}>
            直播價: {Math.ceil(Number(livePrice))}
          </p>
          <p style={{ fontWeight: 'bold', color: 'green', fontSize: '1.5rem', margin: '0.3rem 0' }}>
            商城價: {Math.ceil(Number(onlinePrice))}
          </p>
        </>
      );
    }
  };

  const calculateExchangeCost = (cost) => cost / exchangeRate;
  const calculateShippingCost = (rate) => {
    let cost;
    if (country === 'korea') {
      cost = costKoreanWon;
    } else if (country === 'thailand') {
      cost = costTHB;
    } else {
      cost = costTWD;
    }
    const exchangeCost = calculateExchangeCost(cost);
    const totalCost = weight * rate + (costTWD || exchangeCost);
    return isNaN(totalCost) ? null : Number(totalCost).toFixed(1);
  };

  const renderCostInfo = () => {
    if (country === 'china') {
      if (costRMB) {
        const convertedCost = Math.ceil(Number(costRMB * exchangeRate));
        const shippingCost = Math.ceil(Number(weight * shippingRate * exchangeRate));
        const totalCost = convertedCost + shippingCost;
        return (
          <>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              人民幣成本: {Math.ceil(Number(costRMB))}
            </p>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              台幣換算: {convertedCost} TWD
            </p>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              🚢海快成本: {shippingCost} TWD
            </p>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              總成本: {totalCost} TWD
            </p>
          </>
        );
      }
    } else if (country === 'korea') {
      if (costKoreanWon) {
        return (
          <>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              韓幣成本: {Math.ceil(Number(costKoreanWon))}
            </p>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              台幣換算: {Math.ceil(Number(costKoreanWon / exchangeRate))} TWD
            </p>
          </>
        );
      } else if (costTWD) {
        return (
          <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
            台幣成本: {Math.ceil(Number(costTWD))}
          </p>
        );
      }
    } else if (country === 'thailand') {
      if (costTHB) {
        return (
          <>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              泰銖成本: {Math.ceil(Number(costTHB))}
            </p>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              台幣換算: {Math.ceil(Number(costTHB / exchangeRate))} TWD
            </p>
          </>
        );
      } else if (costTWD) {
        return (
          <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
            台幣成本: {Math.ceil(Number(costTWD))}
          </p>
        );
      }
    }
    return null;
  };

  const renderStyles = () => {
    if (!styles || styles.length === 0) return <p style={{ fontSize: '1.25rem' }}>無樣式</p>;
    return (
      <div>
        {styles.map((styleText, idx) => (
          <div key={idx} style={{ fontSize: '1.25rem', marginBottom: '0.25rem' }}>
            <span>{styleText}</span>
            <button
              style={{ fontSize: '0.9rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem' }}
              onClick={() => setShowBarcode({ ...showBarcode, [idx]: !showBarcode[idx] })}
            >
              {showBarcode[idx] ? '隱藏條碼' : '顯示條碼'}
            </button>
            {showBarcode[idx] && (
              <span style={{ fontSize: '1rem', color: '#555', marginLeft: '0.5rem' }}>
                {styleBarcodes[idx]}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderHistoryEntry = (entry) => {
    const parts = [];
    parts.push(`日期: ${entry.date}`);
    if (entry.costKoreanWon) parts.push(`韓幣成本: ${entry.costKoreanWon}`);
    if (entry.costTWD) parts.push(`台幣成本: ${entry.costTWD}`);
    if (entry.costTHB) parts.push(`泰銖成本: ${entry.costTHB}`);
    if (entry.costRMB) parts.push(`人民幣成本: ${entry.costRMB}`);
    if (entry.livePrice) parts.push(`直播價: ${entry.livePrice}`);
    if (entry.onlinePrice) parts.push(`線上價: ${entry.onlinePrice}`);
    if (entry.remarks) parts.push(`備註: ${entry.remarks}`);
    if (entry.vendor) parts.push(`廠商: ${entry.vendor}`);
    return parts.join(' | ');
  };

  const renderHistoryList = () => {
    const sortedHistory = history ? [...history].reverse() : [];
    let lastEntryText = '';
    return (
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {sortedHistory.map((entry, index) => {
          const entryText = renderHistoryEntry(entry);
          if (entryText === lastEntryText) return null;
          lastEntryText = entryText;
          return (
            <li key={index} style={{ marginBottom: '0.5rem', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '0.5rem', background: '#fff' }}>
              {entryText}
            </li>
          );
        })}
      </ul>
    );
  };

  // 內嵌編輯廠商：直接以輸入框搭配 datalist 呈現
  const handleVendorSave = async () => {
    try {
      const collectionName = country === 'korea' ? 'koreanProducts' : country === 'china' ? 'chinaProducts' : 'thaiProducts';
      const productRef = doc(db, collectionName, id);
      await updateDoc(productRef, { vendor: vendorInput });
      setProduct({ ...product, vendor: vendorInput });
      localStorage.setItem('lastSelectedVendor', vendorInput.trim());
      setEditingVendor(false);
    } catch (error) {
      console.error("更新廠商失敗", error);
    }
  };

  return (
    <div className="container product-view" style={{ padding: '0.5rem', fontFamily: 'Arial, sans-serif', maxWidth: '480px', margin: '0 auto' }}>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center', marginBottom: '0.5rem' }}>商品詳情</h2>
      <div className="product-details" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={image} alt={name} style={{ width: '120px', marginBottom: '0.5rem', borderRadius: '0.5rem' }} />
        <div className="product-info" style={{ width: '100%', padding: '0 0.5rem' }}>
          <div style={{ marginBottom: '0.5rem' }}>
            <p style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>{name}</p>
            <div style={{ borderTop: '1px solid #eee', paddingTop: '0.25rem', marginTop: '0.25rem' }}>
              {renderPriceInfo()}
              <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>備註: {remarks}</p>
            </div>
          </div>
          <div style={{ marginBottom: '0.5rem' }}>
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              料號: {itemNumber} | 廠商:{" "}
              {editingVendor ? (
                <>
                  <input
                    type="text"
                    value={vendorInput}
                    onChange={(e) => setVendorInput(e.target.value)}
                    list="vendorList"
                    style={{ fontSize: '1.25rem' }}
                  />
                  <button
                    style={{ fontSize: '0.9rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem' }}
                    onClick={handleVendorSave}
                  >
                    保存
                  </button>
                  <button
                    style={{ fontSize: '0.9rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem' }}
                    onClick={() => { setEditingVendor(false); setVendorInput(product.vendor || ''); }}
                  >
                    取消
                  </button>
                </>
              ) : (
                <>
                  <span>{product.vendor || '未設定'}</span>
                  <button
                    style={{ fontSize: '0.9rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem' }}
                    onClick={() => setEditingVendor(true)}
                  >
                    編輯
                  </button>
                </>
              )}
            </p>
            <datalist id="vendorList">
              {sortedVendors.map(vendor => (
                <option key={vendor.id} value={vendor.name} />
              ))}
            </datalist>
            <div style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>樣式: {renderStyles()}</div>
          </div>
          <div style={{ marginBottom: '0.5rem', borderTop: '1px solid #eee', paddingTop: '0.25rem' }}>
            <p style={{ fontSize: '1.25rem', textAlign: 'center', margin: 0 }}>重量: {weight}</p>
          </div>
          <div style={{ marginBottom: '0.5rem', borderTop: '1px solid #eee', paddingTop: '0.25rem' }}>
            {renderCostInfo()}
            <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
              🚀直飛成本: {calculateShippingCost(airRate)} TWD
            </p>
            {country === 'korea' && (
              <>
                <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
                  🚢海運成本: {calculateShippingCost(seaRate)} TWD
                </p>
                <p style={{ fontSize: '1.25rem', margin: '0.25rem 0' }}>
                  ✈️轉機成本: {calculateShippingCost(transitRate)} TWD
                </p>
              </>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '0.5rem' }}>
            <button style={{ fontSize: '1.25rem', padding: '0.5rem 1rem' }} onClick={() => navigate(`/edit/${country}/${id}`)}>
              編輯
            </button>
            <button style={{ fontSize: '1.25rem', padding: '0.5rem 1rem' }} onClick={() => setShowHistory(!showHistory)}>
              查看歷史價格
            </button>
          </div>
          {showHistory && (
            <div className="history-container" style={{ marginTop: '0.5rem' }}>
              <h3 style={{ fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.25rem' }}>
                歷史價格（由新到舊）
              </h3>
              {renderHistoryList()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
