import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, setDoc, doc, addDoc, deleteDoc } from 'firebase/firestore';
import db from '../firebaseConfig';
import '../styles.css';

function BatchAddProduct() {
  const { country } = useParams();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const productsRef = collection(
        db,
        country === 'korea'
          ? 'koreanProducts'
          : country === 'china'
          ? 'chinaProducts'
          : 'thaiProducts'
      );
      for (const item of json) {
        const itemNumber = String(item.料號 || '');
        const style = item.樣式 ? item.樣式 : '無樣式';
        const styleBarcode = String(item.樣式條碼 || '');
        const vendorName = String(item.廠商 || '').trim();
        if (vendorName !== '') {
          const vendorsRef = collection(db, 'vendors');
          const vendorQuery = query(vendorsRef, where('country', '==', country), where('name', '==', vendorName));
          const vendorSnapshot = await getDocs(vendorQuery);
          if (vendorSnapshot.empty) {
            await addDoc(vendorsRef, { name: vendorName, country });
          }
        }
        const existingProductByBarcode = await getDocs(query(productsRef, where('styleBarcodes', 'array-contains', styleBarcode)));
        const existingProductByItemNumber = await getDocs(query(productsRef, where('itemNumber', '==', itemNumber)));
        let existingProduct = null;
        if (!existingProductByBarcode.empty) {
          existingProduct = existingProductByBarcode.docs[0];
        } else if (!existingProductByItemNumber.empty) {
          existingProduct = existingProductByItemNumber.docs[0];
        }
        if (existingProduct) {
          const existingData = existingProduct.data();
          const historyEntry = {
            date: new Date().toLocaleDateString(),
            costKoreanWon: existingData.costKoreanWon,
            costTWD: existingData.costTWD,
            costTHB: existingData.costTHB,
            livePrice: existingData.livePrice,
            onlinePrice: existingData.onlinePrice,
            remarks: existingData.remarks
          };
          const updatedStyles = existingData.styles.map((s, i) => 
            existingData.styleBarcodes[i] === styleBarcode ? style : s
          );
          const updatedStyleBarcodes = existingData.styleBarcodes.map((b, i) =>
            existingData.styleBarcodes[i] === styleBarcode ? styleBarcode : b
          );
          if (!updatedStyleBarcodes.includes(styleBarcode)) {
            updatedStyles.push(style);
            updatedStyleBarcodes.push(styleBarcode);
          }
          const updatedData = {
            ...existingData,
            history: [...(existingData.history || []), historyEntry],
            image: item.圖片 || existingData.image,
            name: item.名稱 || existingData.name,
            livePrice: item.直播價 || existingData.livePrice,
            onlinePrice: item.商成價 || existingData.onlinePrice,
            remarks: item.備註 || existingData.remarks,
            costTWD: item.成本台幣 || existingData.costTWD,
            costKoreanWon: item.成本韓幣 || existingData.costKoreanWon,
            costTHB: item.成本泰銖 || existingData.costTHB,
            weight: item.重量 || existingData.weight,
            styles: updatedStyles,
            styleBarcodes: updatedStyleBarcodes,
            vendor: vendorName || existingData.vendor || ''
          };
          if (!existingData.itemNumber && itemNumber) {
            updatedData.itemNumber = itemNumber;
          }
          await setDoc(doc(productsRef, existingProduct.id), updatedData);
        } else {
          const newProduct = {
            image: item.圖片 || '',
            name: item.名稱 || '',
            itemNumber: item.料號 || '',
            livePrice: item.直播價 || '',
            onlinePrice: item.商成價 || '',
            remarks: item.備註 || '',
            costKoreanWon: item.成本韓幣 || '',
            costTWD: item.成本台幣 || '',
            costTHB: item.成本泰銖 || '',
            weight: item.重量 || '',
            styles: [style],
            styleBarcodes: [styleBarcode],
            history: [],
            vendor: vendorName
          };
          const newProductRef = doc(productsRef);
          await setDoc(newProductRef, newProduct);
        }
      }
      window.location.reload();
    };
    reader.readAsArrayBuffer(file);
  };

  const handleExport = async () => {
    const productsRef = collection(
      db,
      country === 'korea'
        ? 'koreanProducts'
        : country === 'china'
        ? 'chinaProducts'
        : 'thaiProducts'
    );
    const snapshot = await getDocs(productsRef);
    const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const formattedProducts = [];
    products.forEach(product => {
      product.styles.forEach((style, index) => {
        formattedProducts.push({
          圖片: product.image,
          名稱: product.name,
          料號: product.itemNumber,
          直播價: product.livePrice,
          商成價: product.onlinePrice,
          備註: product.remarks,
          成本韓幣: product.costKoreanWon,
          成本台幣: product.costTWD,
          成本泰銖: product.costTHB,
          重量: product.weight,
          樣式: style,
          樣式條碼: product.styleBarcodes[index],
          廠商: product.vendor || ''
        });
      });
    });
    const ws = XLSX.utils.json_to_sheet(formattedProducts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, `商品匯出_${new Date().toLocaleString().replace(/[:]/g, '-')}.xlsx`);
  };

  const handleDownloadTemplate = () => {
    const template = [{
      圖片: '',
      名稱: '',
      料號: '',
      直播價: '',
      商成價: '',
      備註: '',
      成本韓幣: '',
      成本台幣: '',
      成本泰銖: '',
      重量: '',
      樣式: '',
      樣式條碼: '',
      廠商: ''
    }];
    const ws = XLSX.utils.json_to_sheet(template);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
    XLSX.writeFile(wb, '批次新增商品範本.xlsx');
  };

  const handleMergeProducts = async () => {
    const productsRef = collection(
      db,
      country === 'korea'
        ? 'koreanProducts'
        : country === 'china'
        ? 'chinaProducts'
        : 'thaiProducts'
    );
    const snapshot = await getDocs(productsRef);
    const products = snapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
    const groups = {};
    products.forEach(product => {
      const itemNumber = product.itemNumber;
      if (itemNumber) {
        if (!groups[itemNumber]) groups[itemNumber] = [];
        groups[itemNumber].push(product);
      }
    });
    for (const itemNumber in groups) {
      const group = groups[itemNumber];
      if (group.length < 2) continue;
      const mainProduct = group.find(prod => prod.weight && prod.weight.trim() !== '' && prod.weight !== '0');
      if (!mainProduct) continue;
      const mergeCandidates = group.filter(prod => prod.id !== mainProduct.id && (!prod.weight || prod.weight.trim() === ''));
      if (mergeCandidates.length === 0) continue;
      let updatedStyles = mainProduct.styles ? [...mainProduct.styles] : [];
      let updatedStyleBarcodes = mainProduct.styleBarcodes ? [...mainProduct.styleBarcodes] : [];
      let updatedHistory = mainProduct.history ? [...mainProduct.history] : [];
      for (const candidate of mergeCandidates) {
        if (candidate.styles && candidate.styleBarcodes) {
          candidate.styles.forEach((style, index) => {
            const barcode = candidate.styleBarcodes[index];
            if (!updatedStyleBarcodes.includes(barcode)) {
              updatedStyles.push(style);
              updatedStyleBarcodes.push(barcode);
            }
          });
        }
        updatedHistory.push({
          date: new Date().toLocaleDateString(),
          mergedProductId: candidate.id
        });
        await deleteDoc(doc(productsRef, candidate.id));
      }
      const updatedData = {
        ...mainProduct,
        styles: updatedStyles,
        styleBarcodes: updatedStyleBarcodes,
        history: updatedHistory
      };
      await setDoc(doc(productsRef, mainProduct.id), updatedData);
    }
    alert("合併完成！");
    window.location.reload();
  };

  return (
    <div className="container">
      <h2>批次新增商品</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>上傳</button>
      <button onClick={handleExport}>匯出所有商品資料</button>
      <button onClick={handleDownloadTemplate}>下載範本</button>
      <button onClick={handleMergeProducts}>合併相同料號商品</button>
    </div>
  );
}

export default BatchAddProduct;
