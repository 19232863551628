import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import db from '../firebaseConfig';
import '../styles.css';

function ClientSideSearch() {
  const { country } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // 搜尋相關 state
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showAddProductPrompt, setShowAddProductPrompt] = useState(false);

  // 直接預設為方格模式 (grid)
  const [viewMode, setViewMode] = useState('grid');

  // 從 URL 讀取搜尋字串，並在初始載入時若有參數就執行搜尋
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const term = params.get('search') || '';
    if (term) {
      // 將 URL 上的搜尋字串轉為大寫後存入 state
      setSearchTerm(term.toUpperCase());
      fetchAndFilterProducts(term);
    } else {
      setFilteredProducts([]);
      setShowAddProductPrompt(false);
    }
  }, [location.search, country]);

  // 從 Firebase 抓取符合搜尋條件的商品（包含圖片）
  async function fetchAndFilterProducts(termInput) {
    // 將搜尋字串轉成大寫
    const term = termInput.trim().toUpperCase();
    if (!term) {
      setFilteredProducts([]);
      setShowAddProductPrompt(false);
      return;
    }
    try {
      const productsCol = collection(
        db,
        country === 'korea' ? 'koreanProducts' : 'thaiProducts'
      );
      const snapshot = await getDocs(productsCol);
      const products = snapshot.docs.map((doc) => ({
        id: doc.id,
        image: doc.data().image || '',
        name: doc.data().name || '',
        itemNumber: doc.data().itemNumber || '',
        styles: doc.data().styles || [],
        styleBarcodes: doc.data().styleBarcodes || [],
      }));
      // 過濾符合搜尋條件的商品，將品名、料號和每個條碼都轉為大寫比對
      const results = products.filter((product) => {
        const name = product.name.toUpperCase();
        const itemNumber = product.itemNumber.toUpperCase();
        const barcodeMatch = product.styleBarcodes.some((barcode) =>
          barcode.toUpperCase().includes(term)
        );
        return name.includes(term) || itemNumber.includes(term) || barcodeMatch;
      });
      results.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredProducts(results);
      setShowAddProductPrompt(results.length === 0);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    const term = searchTerm.trim();
    navigate(`?search=${encodeURIComponent(term)}`);
    fetchAndFilterProducts(term);
  };

  const handleToggleView = () => {
    setViewMode((prev) => (prev === 'grid' ? 'list' : 'grid'));
  };

  const handleAddProduct = () => {
    navigate(
      `/add/${country}?barcode=${encodeURIComponent(
        searchTerm
      )}&search=${encodeURIComponent(searchTerm)}`
    );
  };

  return (
    <div style={{ padding: '1rem', maxWidth: '480px', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center' }}>商品搜尋</h2>
      
      {/* 置中搜尋輸入框 */}
      <div style={{ margin: '0 auto 0.5rem', width: '300px' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.target.select()}
          placeholder="輸入商品名稱、料號或條碼末5碼"
          style={{
            width: '100%',
            padding: '0.5rem',
            fontSize: '1.25rem',
            textAlign: 'center'
          }}
        />
      </div>
      
      {/* 搜尋按鈕 (寬度與搜尋框一致) */}
      <div style={{ margin: '0 auto 0.5rem', width: '300px' }}>
        <button
          onClick={handleSearchClick}
          style={{ width: '100%', padding: '0.5rem 1rem', fontSize: '1.25rem' }}
        >
          搜尋
        </button>
      </div>
      
      {/* ICON 放在搜尋按鈕下面 */}
      <div style={{ marginBottom: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <img
          src={viewMode === 'grid' ? '/icons/list.png' : '/icons/grid.png'}
          alt="切換顯示模式"
          onClick={handleToggleView}
          style={{ width: '32px', height: '32px', cursor: 'pointer' }}
        />
      </div>

      {showAddProductPrompt && (
        <div style={{ marginBottom: '0.5rem', textAlign: 'center' }}>
          <p>未匹配到商品，是否新增商品？</p>
          <button
            onClick={handleAddProduct}
            style={{ padding: '0.5rem 1rem', fontSize: '1.25rem' }}
          >
            新增商品
          </button>
        </div>
      )}

      {filteredProducts.length > 0 && (
        <div
          className="product-list"
          style={
            viewMode === 'grid'
              ? {
                  marginTop: '1rem',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '1rem'
                }
              : { marginTop: '1rem', display: 'block' }
          }
        >
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="product-card"
              onClick={() =>
                navigate(`/view/${country}/${product.id}?search=${encodeURIComponent(searchTerm)}`)
              }
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: viewMode === 'grid' ? 'column' : 'row',
                alignItems: 'center',
                padding: '0.5rem',
                border: '1px solid #ddd',
                borderRadius: '8px',
                cursor: 'pointer',
                marginBottom: viewMode === 'grid' ? '1rem' : '0.5rem'
              }}
            >
              <img
                src={product.image}
                alt={product.name}
                className="product-image-small"
                loading="lazy"
                style={{
                  width: viewMode === 'grid' ? '100%' : '80px',
                  height: 'auto',
                  maxWidth: viewMode === 'grid' ? 'none' : '120px',
                  marginRight: viewMode === 'grid' ? '0' : '0.5rem',
                  marginBottom: viewMode === 'grid' ? '0.5rem' : '0',
                  borderRadius: '4px'
                }}
              />
              <div
                className="product-info"
                style={{
                  fontSize: '1rem',
                  textAlign: viewMode === 'grid' ? 'center' : 'left'
                }}
              >
                <span style={{ color: '#000' }}>{product.name}</span>
                <br />
                <span style={{ color: '#959595' }}>{product.styles.join(' / ')}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ClientSideSearch;
